import React from "react";
import { pickBy, negate, isNil } from "lodash";

class Form extends React.Component {
  state = {
    name: "",
    email: "",
    formSubmitResponse: null,
    submitting: false,
    fieldMsg: null
  };

  formatUrl = (urlStr, params) =>
    urlStr +
    "?" +
    new URLSearchParams(pickBy(params, negate(isNil))).toString();

  formReady = () => {
    const {
      name,
      email,
      phone,
      property,
      arv,
      price,
      costs,
      exit,
      term,
      ownMoney,
      similarDealCount,
      underContract
    } = this.state;
    if (
      !name ||
      !email ||
      !phone ||
      !property ||
      !arv ||
      !price ||
      !costs ||
      !exit ||
      !term ||
      !ownMoney ||
      !similarDealCount ||
      !underContract
    ) {
      return false;
    }
    return true;
  };

  submitForm = e => {
    e.preventDefault();

    if (!this.formReady()) {
      this.setState(() => ({
        fieldMsg: true
      }));
      return;
    }

    this.setState(() => ({
      submitting: true
    }));

    let {
      name,
      email,
      phone,
      property,
      arv,
      price,
      costs,
      exit,
      term,
      ownMoney,
      creditScore,
      similarDealCount,
      underContract
    } = this.state;
    name = name || "Unknown";
    email = email || "Unknown";
    phone = phone || "Unknown";
    property = property || "Unknown";
    arv = arv || "Unknown";
    price = price || "Unknown";
    costs = costs || "Unknown";
    exit = exit || "Unknown";
    term = term || "Unknown";
    ownMoney = ownMoney || "Unknown";
    creditScore = creditScore || "Unknown";
    similarDealCount = similarDealCount || "Unknown";
    underContract = underContract || "Unknown";

    fetch(
      this.formatUrl(
        "https://docs.google.com/forms/d/e/1FAIpQLSd-yIP7QHevPztORnw5wXy3UNlNQJYORr4ikdRIkNnOeCzUfg/formResponse",
        {
          emailAddress: email,
          "entry.1588809440": name,
          "entry.1684567055": phone,
          "entry.1816234769": property,
          "entry.356661792": arv,
          "entry.516521024": price,
          "entry.1404165843": costs,
          "entry.923122800": exit,
          "entry.1435392714": term,
          "entry.1348800648": ownMoney,
          "entry.728215180": creditScore,
          "entry.2129022828": similarDealCount,
          "entry.396274407": underContract
        }
      ),
      {
        method: "POST",
        mode: "no-cors"
      }
    )
      .then(res => {
        if (res.ok || res.status === 0) {
          this.setState(() => ({
            formSubmitResponse: true,
            submitting: false
          }));
        } else {
          this.setState(() => ({
            formSubmitResponse: false,
            submitting: false
          }));
        }
      })
      .catch(e => {
        this.setState(() => ({
          formSubmitResponse: false,
          submitting: false
        }));
        console.log(e);
      });
  };

  changeField = (fieldName, value) => {
    this.setState(() => ({ [fieldName]: value, fieldMsg: null }));
  };

  render() {
    const { submitting, formSubmitResponse, fieldMsg } = this.state;

    if (submitting) {
      return (
        <div className="GetStarted">
          <div style={{ textAlign: "center" }} className="submittingForm">
            <div className="loader" />
            <p style={{ marginTop: 0, marginBottom: "10px" }}>
              Sending Information
            </p>
          </div>
        </div>
      );
    }

    if (formSubmitResponse !== null) {
      return formSubmitResponse ? (
        <div
          className="GetStarted"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <p style={{ margin: 0 }}>Thank you for your interest!</p>
        </div>
      ) : (
        <div
          className="GetStarted"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <p style={{ margin: 0 }}>
            There was a problem submitting your information.{" "}
            <a
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.setState(() => ({ formSubmitResponse: null }))
              }
            >
              Try again.
            </a>
          </p>
        </div>
      );
    }

    return (
      <form className="GetStarted" onSubmit={this.submitForm}>
        <div>
          <label>
            Name <span>*</span>
          </label>
          <input
            type="text"
            value={this.state.name}
            onChange={e => this.changeField("name", e.target.value)}
          />
        </div>
        <div>
          <label>
            Best Contact Phone Number <span>*</span>
          </label>
          <input
            type="text"
            value={this.state.phone}
            onChange={e => this.changeField("phone", e.target.value)}
          />
        </div>
        <div>
          <label>
            Best Email <span>*</span>
          </label>
          <input
            type="email"
            value={this.state.email}
            onChange={e => this.changeField("email", e.target.value)}
          />
        </div>
        <div>
          <label>
            Property Address <span>*</span>
          </label>
          <input
            type="text"
            value={this.state.property}
            onChange={e => this.changeField("property", e.target.value)}
          />
        </div>
        <div>
          <label>
            After Repair Value (ARV) <span>*</span>
          </label>
          <input
            type="text"
            value={this.state.arv}
            onChange={e => this.changeField("arv", e.target.value)}
          />
        </div>
        <div>
          <label>
            Purchase Price <span>*</span>
          </label>
          <input
            type="text"
            value={this.state.price}
            onChange={e => this.changeField("price", e.target.value)}
          />
        </div>
        <div>
          <label>
            Repair Costs <span>*</span>
          </label>
          <input
            type="text"
            value={this.state.costs}
            onChange={e => this.changeField("costs", e.target.value)}
          />
        </div>
        <div>
          <label>
            Exit Strategy <span>*</span>
          </label>
          <input
            type="text"
            value={this.state.exit}
            onChange={e => this.changeField("exit", e.target.value)}
          />
        </div>
        <div>
          <label>
            Term of Loan Needed <span>*</span>
          </label>
          <input
            type="text"
            value={this.state.term}
            onChange={e => this.changeField("term", e.target.value)}
          />
        </div>
        <div>
          <label>
            How much of your own money do you have to work the deal?{" "}
            <span>*</span>
          </label>
          <input
            type="text"
            value={this.state.ownMoney}
            onChange={e => this.changeField("ownMoney", e.target.value)}
          />
        </div>
        <div>
          <label>If you know your credit score, what is it?</label>
          <input
            type="text"
            value={this.state.creditScore}
            onChange={e => this.changeField("creditScore", e.target.value)}
          />
        </div>
        <div>
          <label>
            How many deals like this have you done? <span>*</span>
          </label>
          <input
            type="text"
            value={this.state.similarDealCount}
            onChange={e => this.changeField("similarDealCount", e.target.value)}
          />
        </div>
        <div>
          <label>
            Do you have the property under contract? <span>*</span>
          </label>
          <input
            type="text"
            value={this.state.underContract}
            onChange={e => this.changeField("underContract", e.target.value)}
          />
        </div>
        {fieldMsg && (
          <p style={{ textAlign: "center", marginBottom: 0 }}>
            Please fill out all required fields.
          </p>
        )}
        <div className="SubmitBtn">
          <button type="submit">Submit</button>
        </div>
      </form>
    );
  }
}

export default Form;
