import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Page from "../components/Page";
import Home from "../components/Home";
import { HTMLContent } from "../components/Content";

const HomePage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Page seoTitle="Home">
      <Home
        {...post.frontmatter}
        mainContent={post.html}
        contentComponent={HTMLContent}
      />
    </Page>
  );
};

HomePage.propTypes = {
  data: PropTypes.object.isRequired
};

export default HomePage;

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        summary
        url
        homeLink
        getStartedLink
        contactLink
      }
    }
  }
`;
