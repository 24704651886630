import React, { PureComponent } from "react";
import ReactPlayer from "react-player";
import {
  FaSearchDollar,
  FaFacebookSquare,
  FaLink,
  FaEnvelope
} from "react-icons/fa";

import Content from "./Content";
import GetStarted from "./GetStarted";

const PAGES = {
  HOME: "home",
  CONTACT: "contact",
  GET_STARTED: "get_started"
};
export default class Main extends PureComponent {
  state = {
    loadingIframe: true,
    iconVisible: false,
    pageContentVisible: false,
    currentPage: PAGES.HOME
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState(() => ({
        iconVisible: true
      }));
      setTimeout(() => {
        this.setState(() => ({
          pageContentVisible: true
        }));
      }, 0);
    }, 1000);
  }

  videoReady = () => {
    this.setState(() => ({
      loadingIframe: false
    }));
  };

  changePage = page => {
    this.setState({ currentPage: page });
  };

  renderHome = () => {
    const { mainContent, contentComponent } = this.props;
    const AboutContent = contentComponent || Content;
    return (
      <div className="content-paragraph">
        <p className="About">
          <AboutContent content={mainContent} />
        </p>
      </div>
    );
  };

  renderContact = () => {
    return (
      <div className="ContactBlock">
        <label>Mark Owens</label>
        <ul>
          <a href="https://markowens.com/" target="_blank">
            <li>
              <FaLink />
              <label>Personal Website</label>
            </li>
          </a>
          <a href="mailto:mark@markowens.com">
            <li>
              <FaEnvelope />
              <label>mark@markowens.com</label>
            </li>
          </a>
          <a
            href="https://www.facebook.com/groups/420244028368348/"
            target="_blank"
          >
            <li>
              <div>
                <FaFacebookSquare />
                <label>Facebook</label>
              </div>
            </li>
          </a>
        </ul>
      </div>
    );
  };

  renderGetStarted = () => {
    return (
      <div style={{ textAlign: "left" }}>
        <GetStarted />
      </div>
    );
  };
  renderPage = () => {
    switch (this.state.currentPage) {
      case PAGES.HOME:
        return this.renderHome();
      case PAGES.CONTACT:
        return this.renderContact();
      case PAGES.GET_STARTED:
        return this.renderGetStarted();
    }
  };

  render() {
    const {
      iconVisible,
      pageContentVisible,
      loadingIframe,
      currentPage
    } = this.state;
    const {
      title,
      summary,
      url,
      homeLink,
      getStartedLink,
      contactLink
    } = this.props;

    return (
      <div id="main">
        <div className={iconVisible ? "logo fadeIn" : "logo"}>
          <FaSearchDollar />
        </div>
        <div className="content">
          <div className="inner">
            <h1>{title}</h1>
            <p>{summary}</p>
          </div>
        </div>
        <nav>
          <ul>
            <li>
              <a
                href="javascript:;"
                onClick={() => this.changePage(PAGES.HOME)}
                style={{
                  fontWeight: currentPage === PAGES.HOME ? "bold" : "normal"
                }}
              >
                {homeLink}
              </a>
            </li>
            <li>
              <a
                href="javascript:;"
                onClick={() => this.changePage(PAGES.GET_STARTED)}
                style={{
                  fontWeight:
                    currentPage === PAGES.GET_STARTED ? "bold" : "normal"
                }}
              >
                {getStartedLink}
              </a>
            </li>
            <li>
              <a
                href="javascript:;"
                onClick={() => this.changePage(PAGES.CONTACT)}
                style={{
                  fontWeight: currentPage === PAGES.CONTACT ? "bold" : "normal"
                }}
              >
                {contactLink}
              </a>
            </li>
          </ul>
        </nav>
        <div
          className={
            !loadingIframe || pageContentVisible
              ? "pageContent fadeIn"
              : "pageContent"
          }
        >
          {this.renderPage()}
        </div>
        {currentPage === PAGES.HOME && (
          <div
            className={`videoPlayer ${loadingIframe ? "loading" : "fadeIn"}`}
          >
            <div className="loadingDiv">
              <div className="loader" />
            </div>
            <ReactPlayer
              url={url}
              onReady={this.videoReady}
              className="player"
              width="100%"
              height="100%"
            />
          </div>
        )}
      </div>
    );
  }
}
